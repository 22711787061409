export class User {
  constructor(public id:number,
              public firstName: String,
              public lastName: String,
              public email: String,
              public adress: String,
              public phoneNumber: String,
              public password: String,
              public password_confirmation: String,

  ) {
  }
}
