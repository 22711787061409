import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import {LoginComponent} from './pages/login/login/login.component';
import {RegisterComponent} from './pages/login/register/register.component';

export const AppRoutes: Routes = [
  {
    path: '',
    //redirectTo: 'dashboard',
    redirectTo: 'login',
    pathMatch: 'full',
  }, {
    path: '',
    component: AdminLayoutComponent,
    children: [
        {
      path: '',
      loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
  }]},
  // {
  //   path: '**',
  //   redirectTo: 'dashboard'
  // },
  {
    path : 'login',
     component : LoginComponent
  },
  {
    path : 'register',
    component : RegisterComponent
  }
]
