import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  register(user: Object): Observable<Object> {
    return this.http.post(`${environment.apiBaseUrl}/register`, user);
  }

  login(email, password){
    //console.log(email, password);
    return this.http.post(`${environment.apiBaseUrl}/login`, {email, password});


  }
}
