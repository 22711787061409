import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class InvestorService {

  constructor(private http: HttpClient) { }

  register(investor: Object): Observable<Object> {
    return this.http.post(`${environment.apiBaseUrl}/register`, investor);
  }

  login(email, password){
    //console.log(email, password);
    return this.http.post(`${environment.apiBaseUrl}/login`, {email, password});


  }

}
