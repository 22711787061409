import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  email: string;
  password:string;
  message = null;
  constructor(private router: Router,
              private service : UserService
              ) { }

  ngOnInit(): void {
  }

  login(){

    this.router.navigateByUrl('/register');
  }

  auth(){
    console.log('loggin');
    console.log(this.email+" "+ this.password);
    this.service.login(this.email, this.password).subscribe(
      data => {
        console.log(data);
        this.router.navigateByUrl('/dashboard');
      }, error => {
        console.log('afsfas' + error)
        this.message = 'Mot de Passe ou identifiant Incorrect';
      }
    );
  }

}
