import { Component, OnInit } from '@angular/core';
import {Investor} from '../../../models/investor';
import {InvestorService} from '../../../services/investor.service';
import {Router} from '@angular/router';
import {User} from '../../../models/user';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
user: User = null;
  constructor(private service: InvestorService,
              private router: Router
              ) { }

  ngOnInit(): void {
    this.user = new User(0,'','','','','','','');
  }

  register(){
    console.log(this.user);
    this.service.register(this.user).subscribe(
      data => {
        console.log('Registered Successfully');
        this.router.navigate(['/login']);
      }
    );

  }

}
